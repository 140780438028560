
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import News from "../../models/News.model";
import {DateTime} from "luxon";

@Component
export default class NewsListComponent extends Vue {
  @Prop({ required: true })
  public news!: News[];

  @Prop({ default: false })
  private isLoading!: boolean;

  @Prop({ default: '' })
  private search!: string;

  @Prop({ required: true })
  public updateNews!: (newsId: string) => void;

  @Prop({ required: true })
  public deleteNews!: (newsId: string) => void;

  @Prop({required: true})
  public duplicateNews!: (newsId: string) => void;

  /**
   * headers in the table
   */
  private headers = [
    { text: this.$t('INFO_FEED.HEADERS.MESSAGE'), align: 'start', sortable: true, value: 'subTitle' },
    { text: this.$t('INFO_FEED.HEADERS.DATE'), align: 'start', sortable: true, value: 'createdOn', formatter: this.translateDate },
    { text: this.$t('INFO_FEED.HEADERS.VALID_FROM'), align: 'start', sortable: true, value: 'validFrom', formatter: this.translateDate },
    { text: this.$t('INFO_FEED.HEADERS.ACTIONS'), align: 'start', sortable: false, value: 'actions' }
  ];

  /**
   * calls callback function to updated selected news
   */
  private updateItem(item: any): any {
    this.updateNews(item.id!);
  }

  /**
   * checks if editing item is enabled
   * @param item
   * @private
   */
  private isEnabled(item: any): any {
    try {
      const validTo = DateTime.fromISO(item.validTo);
      if(!validTo) return false;
      return DateTime.now() < validTo;
    } catch(_) {
      return false;
    }
  }

  /**
   * calls callback function to delete the news
   */
  private deleteItem(item: News): any {
    this.deleteNews(item.id!);
  }

  private duplicateItem(item: News): void {
    this.duplicateNews(item.id!);
  }

  /**
   * translates the date with the custom translater plugin, checks if the date is set to avoid 'invalid dateTime's in
   * the table
   * @param date
   * @private
   */
  private translateDate(date?: string): string {
    // if date doesn't exist, just return placeholder
    if(!date) return '-';

    // return the translated date
    return this.$formatDateFromString(date);
  }
}
